.label {
  color: white;
  padding: 8px;
}
.multiline {
  word-wrap: break-word;
}

.success {background-color: #4CAF50;} /* Green */
.info {background-color: #2196F3;} /* Blue */
.warning {background-color: #ff9800;} /* Orange */
.danger {background-color: #f44336;} /* Red */
.other {background-color: #e7e7e7; color: black;} /* Gray */

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: green;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.areablock {
  display: inline-block;
  /* margin-left: 23%; */
  /* margin-right: 10%; */
  width: 50%;
  /* border: none; */
  background-color: #FFFFFF;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  /* text-align: center; */
}
.inputblock {
  display: inline-block;
  font-family:monospace;
  width: 75%;
  /* margin-right: 10%; */
  /* border: none; */
  background-color: #FFFFFF;
  padding: 14px 28px;
  font-size: 16px;
  /* text-align: center; */
}
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #4CAF50;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.smallblock {

  display: inline-block;
  width: 25%; 
  border: none;
  background-color: #4CAF50;
  padding: 14px 28px;
  font-size: 16px;

  text-align: center;
}

.block:hover {
  background-color: #ddd;
  color: black;
}
.cellContainer {
  width: 25%;
  float: left;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

